import { Controller } from 'stimulus'
import { jsRequest } from "@react/helpers/fetch"

export default class extends Controller {
  static targets = ['task']

  selectTask() {
    const taskBlueprintId = parseInt(this.taskTarget.value)
    if(!taskBlueprintId) return

    const url = new URL(this.data.get('taskUrl'))
    url.searchParams.set("task_blueprint", taskBlueprintId)
    jsRequest(url)
  }
}

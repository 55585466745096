import BaseNotificationController from './baseNotification_controller'

export default class extends BaseNotificationController {

  // PUSH NOTIFICATIONS

  checkPushSubscription() {
    // If push notifications is supported
    if (!('Notification' in window) || !('PushManager' in window)) {
      console.error('This browser does not support desktop notification')
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      this._checkSubscriptions()
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      console.info('Requesting permission')
      Notification.requestPermission((permission) => {
        console.info(permission)
        if (permission === 'granted') {
          console.info('Permission to receive push notifications has been granted')
          this._checkSubscriptions()
        }
      })
    }
  }

  // Private methods

  // If permission granted, check if the subscription already exist
  // If not, create it and save it
  _checkSubscriptions() {
    navigator.serviceWorker.ready
      .then((sw) => { return sw.pushManager.getSubscription() })
      .then((browsersub) => {
        // Unsubscribe if the key if not set, and create a new subscription
        if (browsersub) {
          if (browsersub.options.applicationServerKey === null) { browsersub.unsubscribe() }
        }
        // Compare browser subscription with current user DB subscriptions
        this._buildGetRequest('/notifications/current_subscriptions')
          .then((response) => { return response.json() })
          .then((current_endpoints) => {
            // If the subscription already exist, return
            if (browsersub && current_endpoints.includes(browsersub.endpoint)) { return }
            // Else, register the subscription
            this._createSubscription()
          })
      })
  }

  // Create the subscription
  _createSubscription() {
    // Get the server auth key
    const vapidkey = new Promise((resolve, reject) => {
      this._buildGetRequest('/notifications/vapid_key')
        .then(response => response.json())
        .then((data) => {
          window.vapidPublicKey = new Uint8Array(data)
          resolve(data)
        })
    })

    vapidkey.then(() => {
      navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => {
          // Create a new subscription
          serviceWorkerRegistration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: window.vapidPublicKey
            })
            // Register it in our DB
            .then((pushSubscription) => {
              if (pushSubscription.endpoint) {
                this._buildRequest('/notifications/subscribe_push', 'POST', { subscription: pushSubscription.toJSON() })
              } else {
                console.error("Subscription endpoint is missing!")
              }
            })
        })
    })
  }
}

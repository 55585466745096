import { Controller }  from "stimulus"

export default class extends Controller {

  static targets = [
    "paymentDelay",
    "endOfMonth",
    "clientCode",
    "clientName",
    "clientNameAddition",
    "clientNameAddition2",
    "clientAddress",
    "reminderCheckbox",
    "onlinePaymentCheckbox"
  ]

  updateClient() {
    if (this.hasPaymentDelayTarget) {
      const paymentDelay = this._readDataElement().client.payment_delay
      if (paymentDelay) this.paymentDelayTarget.value = paymentDelay
    }

    if (this.hasEndOfMonthTarget) {
      const endOfMonth = this._readDataElement().client.end_of_month
      if (endOfMonth) this.endOfMonthTarget.value = endOfMonth
    }

    if (this.hasClientNameTarget) this.updateBillingData()

    if (this.hasReminderCheckboxTarget) this.updateReminderCheckbox()

    if (this.hasOnlinePaymentCheckboxTarget) this.updateOnlinePaymentCheckbox()
  }

  updateSite() {
    const siteVatRateId = this._readDataElement().site.vat_rate?.id
    if (siteVatRateId) this._setVatRates(siteVatRateId)
  }

  updateSupplier(e) {
    const dataElement = document.getElementById("suppliers")
    const props       = JSON.parse(dataElement.dataset.reactProps)
    const supplier    = props.options.find(supplier => supplier.id == e.target.value)
    if (supplier && supplier.vatRateId) this._setVatRates(supplier.vatRateId)
  }

  updateBillingData() {
    const billing_details = this._readDataElement().client.billing_details
    if (!billing_details) return

    if (this.hasClientCodeTarget) {
      const clientCode = billing_details.client_code
      this.clientCodeTarget.value = clientCode
    }

    if (this.hasClientNameTarget) {
      const clientName = billing_details.client_name
      this.clientNameTarget.value = clientName
    }

    if (this.hasClientNameAdditionTarget) {
      const clientNameAddition = billing_details.client_name_addition
      this.clientNameAdditionTarget.value = clientNameAddition
    }

    if (this.hasClientNameAddition2Target) {
      const clientNameAddition2 = billing_details.client_name_addition2
      this.clientNameAddition2Target.value = clientNameAddition2
    }

    if (this.hasClientAddressTarget) {
      const clientAddress = billing_details.client_address
      this.clientAddressTarget.value = clientAddress
    }
  }

  updateReminderCheckbox() {
    const invoiceRemindersEnabled = this._readDataElement().client.invoice_reminders_enabled_by_default
    this.reminderCheckboxTarget.checked = invoiceRemindersEnabled;
  }

  updateOnlinePaymentCheckbox() {
    const onlinePaymentEnabledByDefault = this._readDataElement().client.online_payment_enabled_by_default
    this.onlinePaymentCheckboxTarget.checked = onlinePaymentEnabledByDefault;
  }

  addLine() {
    setTimeout(() => {
      let lines =
      lines = document.querySelectorAll(".nested-fields")
      lines = Array.from(lines).filter(row => row.style.display != "none")
      lines.forEach((line, index) => {
        const quill = line.querySelector(".ql-editor")
        const order = line.querySelector("input[name*='[order]']")
        if (order)    order.value = index + 1
        if (!quill) reloadReact(line)
        // Update global client discount
        const lastLineIndex = lines.length - 1
        if (index == lastLineIndex) {
          const data           = this._readDataElement()
          const clientDiscount = data?.client?.discount
          const discountField  = line.querySelector("input[name*='[discount]']")
          if (clientDiscount && discountField) discountField.value = clientDiscount
        }
      })
    }, 300)
  }

  updateCurrency(e) {
    const new_currency = e.target.value
    const currencyElement = document.querySelector('[data-financial--total-currency]')
    currencyElement.setAttribute('data-financial--total-currency', new_currency)
  }


  // private

  _setVatRates(vatRateId) {
    const vatRates = Array.from(document.querySelectorAll("select[name*='[vat_rate_id]']"))
    vatRates.forEach(vatRate => vatRate.value = vatRateId)
  }

  _readDataElement() {
    const dataElement = document.getElementById("asset-print")
    if (!dataElement) return

    return JSON.parse(dataElement.innerText)
  }
}

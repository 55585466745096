import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['hidden']

  connect() {
    this.checkVisibility()

    document.addEventListener("fieldvalue:changed", (event) => {
      this.handleFieldValueChanged(event)
    })
  }

  disconnect() {
    document.removeEventListener("fieldvalue:changed", this.handleFieldValueChanged.bind(this))
  }

  change(event) {
    let option
    let isChecked
    const extraFieldId = this.data.get('extra-field-id')

    option       = event.currentTarget.value
    isChecked    = option !== ""

    this.element.dispatchEvent(
      new CustomEvent("fieldvalue:changed", {
        bubbles: true,
        detail: {
          extraFieldId,
          option,
          checked: isChecked
        }
      })
    )
  }

  handleFieldValueChanged(event) {
    const dependencyId = this.data.get('dependency-id')
    const option       = this.data.get('option')
    const detail       = event.detail

    if (detail.extraFieldId === dependencyId) {
      if (detail.option === option  && detail.checked) {
        this.show()
      } else {
        this.hide()
        this.resetFields()
      }
    }
  }

  show() {
    const hideClass = this.data.get('d-none-class')
    this.element.classList.remove(hideClass)

    this.element.querySelectorAll('select, textarea, input').forEach(input => {
      if (input.dataset.originalRequired === "true") {
        input.setAttribute('required', 'required')
      }
    })
  }

  hide() {
    const hideClass = this.data.get('d-none-class')
    this.element.classList.add(hideClass)

    this.element.querySelectorAll('select, textarea, input').forEach(input => {
      input.dataset.originalRequired = input.required
      input.removeAttribute('required')
    })
  }

  resetFields() {
    const inputs = this.element.querySelectorAll('input, select, textarea')

    inputs.forEach(input => {
      input.value = ''
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })
  }

  checkVisibility() {
    const visibility = this.data.get('visibility')

    if (visibility === 'true') {
      this.show()
    } else {
      this.hide()
    }
  }
}
